exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experts-js": () => import("./../../../src/pages/experts.js" /* webpackChunkName: "component---src-pages-experts-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-js": () => import("./../../../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-advisory-js": () => import("./../../../src/pages/services/advisory.js" /* webpackChunkName: "component---src-pages-services-advisory-js" */),
  "component---src-pages-services-ai-js": () => import("./../../../src/pages/services/ai.js" /* webpackChunkName: "component---src-pages-services-ai-js" */),
  "component---src-pages-services-design-js": () => import("./../../../src/pages/services/design.js" /* webpackChunkName: "component---src-pages-services-design-js" */),
  "component---src-pages-services-engagement-models-js": () => import("./../../../src/pages/services/engagement-models.js" /* webpackChunkName: "component---src-pages-services-engagement-models-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-product-engineering-js": () => import("./../../../src/pages/services/product-engineering.js" /* webpackChunkName: "component---src-pages-services-product-engineering-js" */),
  "component---src-pages-services-startups-js": () => import("./../../../src/pages/services/startups.js" /* webpackChunkName: "component---src-pages-services-startups-js" */)
}


// import React, { createContext, useContext, useState } from "react";

// const GetACallContext = createContext();

// export const GetACallProvider = ({ children }) => {
//   const [showPop, setShowPop] = useState(false);
//   // Function to toggle popup visibility
//   setShowPop(value);
//   // const togglePopup = (value) => {
//   // };
//   return (
//     <GetACallContext.Provider value={{ showPop, setShowPop }}>
//       {children}
//     </GetACallContext.Provider>
//   );
// };

// export const useGetAcall = () => {
//   return useContext(GetACallContext); 
// };



import React, { createContext, useContext, useState } from "react";

const GetACallContext = createContext();

export const GetACallProvider = ({ children }) => {
  const [showPop, setShowPop] = useState(false);

  // Toggle popup visibility
  const togglePopup = (value) => setShowPop(value);

  return (
    <GetACallContext.Provider value={{ showPop, setShowPop , togglePopup}}>
      {children}
    </GetACallContext.Provider>
  );
};

export const useGetAcall = () => useContext(GetACallContext);
